<template>
  <el-table
    ref="table"
    :data="tableData"
    :height="`calc(100vh - 300px)`"
    class="dock-view-table width-100"
    border
  >
    <el-table-column
      v-for="(item) in tableHeader"
      :key="item.field"
      :prop="item.field"
      :label="item.label"
      :width="item.width"
      :fixed="item.fixed"
      align="center"
      :class-name="item.field === 'bookingTimeFormat'?'booking-time-format-col':''"
    >
      <template #default="scope">
        <div
          class="dock-item"
          :style="{
            backgroundColor:scope.row[item.field].backgroundColor
          }"
        >
          <div class="dock-item-top">
            <p>
              <b>{{ scope.row[item.field].shipperName }}</b>
            </p>
            <p>
              <b>{{ scope.row[item.field].plateNo }}</b>
            </p>
          </div>
          <p>
            {{ showBookingTime(scope.row[item.field].checkInDate) }}
          </p>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
import timeUtils from '@/utils/moment';
import { SHIP_STATUS } from '../constant';

export default {
  name: 'DockTableView',
  components: {
  },
  mixins: [],
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    tableHeader: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      SHIP_STATUS,
    };
  },
  computed: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
    showBookingTime(val) {
      if (val) {
        return timeUtils.format(val);
      }
      return '';
    },
  },
};
</script>
<style lang="scss" scoped>
.cell {
  min-height: 64px;
}

.inside-cell {
  & > div {
    width: 100%;
    text-align: center;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    color: #606266;
  }

  & > .inside-cell-car-time {
    text-align: right;
  }
}

.time-col {
  display: flex;
  align-items: center;
  justify-content: center;
}

:deep(.el-table__body) .el-table__row > td {
  padding: 0;
}

:deep(.el-table__body) .el-table__row > td > .cell {
  padding: 0;
}

:deep(.el-tooltip__popper.is-light) {
  border-color: #dcdfe6;
}

.tooltip-inside {
  width: 200px;

  & > p {
    padding: 4px 0;
  }
}

.booking-time-format {
  height: 64px;
  width: 100%;
}

.dock-item {
  height: 130px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  /* background-color: rgba(245, 154, 35, 1); */

  /* background-color: rgba(149, 242, 4, 1); */
}

.dock-item-top {
  text-align: left;
}
</style>
<style lang="scss">

.el-table th.booking-time-format-col {
  padding: 0;

  & > div.cell {
    padding: 0;
    height: 64px;
  }
}

.dock-view-table .el-table__header .has-gutter th.gutter:last-of-type {
  border-bottom: 1px solid transparent;
  border-bottom-width: 1px;
}

.width-100 {
  width: 100%;
}
</style>
