const ArrivalRegistrationFromInfo = [{
  label: '入库单号:',
  prop: 'deliverOrderNo1',
}, {
  label: '货主名称:',
  prop: 'deliverOrderNo2',
}, {
  label: '入库单类型:',
  prop: 'deliverOrderNo3',
}, {
  label: '货主订单号:',
  prop: 'deliverOrderNo4',
}, {
  label: '外部单号:',
  prop: 'deliverOrderNo5',
}, {
  label: '预约送货时间:',
  prop: 'deliverOrderNo6',
}, {
  label: '备注:',
  prop: 'deliverOrderNo7',
}];
export default ArrivalRegistrationFromInfo;
