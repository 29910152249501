<template>
  <div class="right-table-legend">
    <div
      v-for="item in DOCK_LEGENDS"
      :key="item.color"
      class="legend"
    >
      <div :style="{backgroundColor:item.color}" /> <span>{{ item.text }}</span>
    </div>
  </div>
</template>
<script>
import { DOCK_LEGENDS } from '../constant';

export default {
  name: 'DockTableLegend',
  components: {
  },
  filters: {
  },
  mixins: [],
  props: {
    time: {
      type: String,
      default: () => '',
    },
  },

  data() {
    return {
      DOCK_LEGENDS,
    };
  },
  computed: {
  },
  created() {
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
.right-table-legend {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;

  .legend {
    display: flex;
    align-items: center;

    div {
      width: 40px;
      height: 20px;
      border-radius: 2px;
      margin-left: 32px;
      margin-right: 8px;
    }

    span {
      font-size: 14px;
      font-weight: 400;
      color: #606266;
    }
  }
}
</style>
