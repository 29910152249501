<template>
  <nh-detail-page class="details-page">
    <template #default>
      <div class="title">
        <h3>到货登记</h3>
      </div>
      <div class="body store_info">
        <el-form
          ref="form"
          label-width="80px"
          label-position="right"
          :model="form"
        >
          <el-row :gutter="24">
            <el-col :span="6">
              <!-- 需要把规则分开 以适应 动态控制 分派月台的验证问题 -->
              <el-form-item
                label="车牌号"
                prop="plateNo"
                :rules="{required:true, trigger: 'blur' ,validator:validatorPlateNo}"
              >
                <el-input v-model="form.plateNo" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="分配月台"
                prop="dockId"
                :rules="{ required: isAssignDock, message: '请选择分配月台', trigger: 'blur' }"
              >
                <el-select
                  v-model="form.dockId"
                  placeholder="请输选择月台"
                  value-key="id"
                  :loading="loading.loadWarehousePlatformPage"
                >
                  <el-option
                    v-for="item in storeAreaDocks"
                    :key="item.id"
                    :label="item.dockCode"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <nh-button type="text" @click="handleShowDockViewDialog">
                查看月台使用情况
              </nh-button>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item
                label="是否需要装卸"
                prop="handling"
                label-width="120px"
                :rules="{ required: true, message: '请选择', trigger: 'change' }"
              >
                <el-select
                  v-model="form.handling"
                  placeholder="请选择"
                  @change="handlingChange"
                >
                  <el-option
                    v-for="item in URGENT"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col v-if="form.handling" :span="6">
              <el-form-item
                label="是否外包装卸"
                prop="outsourceHandling"
                label-width="120px"
                :rules="{ required: true, message: '请选择', trigger: 'change' }"
              >
                <el-select
                  v-model="form.outsourceHandling"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in URGENT"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="2" :offset="6">
              <nh-button
                :loading="loading.updateReceiptOrderCheckIn"
                type="primary"
                @click="handleSubmitRegistration"
              >
                登记
              </nh-button>
            </el-col>
          </el-row>
        </el-form>
        <InboundOrderDetail
          :is-only-detail="false"
          :show-dock="false"
          @fileData="getFileData"
          @storeData="getStoreData"
        />
        <DockViewDialog
          ref="dockViewDialog"
          :basic-docks="storeAreaDocksBasicInfo"
          :loading="loading.loadDockSubscriberList"
        />
      </div>
    </template>
  </nh-detail-page>
</template>
<script>
import loadingMixin from '@thales/loading';
import moment from 'moment';
import InboundOrderDetail from '../detail/index.vue';
import DockViewDialog from '../dockViewDialog/index.vue';
import {
  warehousePlatformPage, receiptOrderCheckIn, dockSubscriberList, receiptOrderRuleDetail,
} from '../api';
import ArrivalRegistrationFromInfo from './arrivalRegistrationForm';
import {
  DOCK_CELL_WIDTH, DOCK_TYPE_ENUM_VALUE, RECEIPT_STSTUS_ENUM_VALUE, SHIP_STATUS, URGENT,
} from '../constant';

export default {
  name: 'InboundOrderArrivalRegistration',
  components: {
    InboundOrderDetail,
    DockViewDialog,
  },
  mixins: [loadingMixin],
  data() {
    return {
      ArrivalRegistrationFromInfo,
      URGENT,
      StoreData: {},
      fileData: [],
      storeAreaDocks: [],
      storeAreaDocksBasicInfo: {
        column: [],
        data: [],
      },
      form: {
        plateNo: '',
        dockId: '',
        handling: undefined,
        outsourceHandling: undefined,
      },
      loading: {
        updateReceiptOrderCheckIn: false,
        loadWarehousePlatformPage: false,
        loadDockSubscriberList: false,
      },
      isAssignDock: false,
    };
  },
  async created() {
    await this.loadReceiptOrderRuleDetail();
    await this.loadWarehousePlatformPage();
  },
  methods: {
    validatorPlateNo(rule, value, callback) {
      if (!value) {
        callback(new Error('请输入车牌号'));
      } else if (value && value.length > 8) {
        callback(new Error('请输入正确的车牌号'));
      } else {
        callback();
      }
    },
    handlingChange(val) {
      if (val === URGENT[0].value) {
        this.form.outsourceHandling = URGENT[0].value;
      } else {
        this.form.outsourceHandling = undefined;
      }
    },
    handleShowDockViewDialog() {
      this.$refs.dockViewDialog.show();
    },
    async handleSubmitRegistration() {
      await this.$refs.form.validate();
      this.updateReceiptOrderCheckIn({
        dockId: this.form.dockId,
        receiptOrderId: this.$route.query.id,
        plateNo: this.form.plateNo,
        handling: this.form.handling,
        outsourceHandling: this.form.outsourceHandling,
      });
    },
    buildDocksTableCol(basicDocks = []) {
      if (basicDocks.length === 0) {
        return [];
      }
      return [
        ...basicDocks.map((item) => ({
          field: `dock${item.id}`,
          id: item.id,
          label: item.dockCode,
          width: DOCK_CELL_WIDTH,
        })),
      ];
    },
    buildDocksLayout(basicColumn) {
      this.storeAreaDocksBasicInfo.column = this.buildDocksTableCol(basicColumn);
      this.storeAreaDocksBasicInfo.data = [];
    },
    buildDocksData(sourceData) {
      let data = [];
      const columnKeys = this.storeAreaDocksBasicInfo.column.map((item) => item.field);
      // 根据 服务端数据 组装 表格数据
      sourceData.forEach((sourceItem) => {
        sourceItem.dockSubscribers.forEach((dockSubItem) => {
          let backgroundColor = '';
          if (dockSubItem.receiptOrderStatus
          === RECEIPT_STSTUS_ENUM_VALUE.PRE_ACCEPT) {
            backgroundColor = SHIP_STATUS.REGISTERED;
          }
          if (dockSubItem.receiptOrderStatus
          === RECEIPT_STSTUS_ENUM_VALUE.ACCEPTING) {
            backgroundColor = SHIP_STATUS.ACCEPTANCE;
          }
          // 只展示待验收 和已登记的数据
          if (dockSubItem.receiptOrderStatus === RECEIPT_STSTUS_ENUM_VALUE.PRE_ACCEPT
          || dockSubItem.receiptOrderStatus === RECEIPT_STSTUS_ENUM_VALUE.ACCEPTING) {
            data.push({
              [`dock${sourceItem.dock.id}`]: {
                ...dockSubItem,
                backgroundColor,
                // 已登记 不展示开始验收时间
                checkInDate: dockSubItem.receiptOrderStatus === RECEIPT_STSTUS_ENUM_VALUE.PRE_ACCEPT ? '' : dockSubItem.checkInDate,
              },
            });
          }
        });
      });
      // 补全 所有月台数据 防止渲染报错
      data = data.map((sourceItem) => {
        const newItem = { ...sourceItem };
        const dataKeys = Object.keys(sourceItem);
        // 本行 数据 未包含 的月台
        const waitCompletionKeys = columnKeys.filter((item) => !dataKeys.includes(item));
        waitCompletionKeys.forEach((key) => {
          newItem[key] = {
            backgroundColor: '',
            shipperName: '',
            plateNo: '',
            checkInDate: '',
          };
        });
        return {
          ...newItem,
        };
      });
      return data;
    },
    async updateReceiptOrderCheckIn(data) {
      await receiptOrderCheckIn({}, data);
      this.$message({ type: 'success', message: '登记成功!' });
      this.$router.go(-1);
    },
    async loadWarehousePlatformPage() {
      const resp = await warehousePlatformPage({ page: 1, size: 1000 }, {});
      // 预约提货 月台布局只展示发货月台 且未禁用
      const storeAreaDocks = resp.records.filter((item) => item.storeArea && item.status !== 'DISABLED');
      this.storeAreaDocks = storeAreaDocks;
      this.buildDocksLayout(storeAreaDocks);
      await this.loadDockSubscriberList();
    },
    async loadDockSubscriberList() {
      const result = await dockSubscriberList({}, {
        dockType: DOCK_TYPE_ENUM_VALUE.RECEIPT,
        checkInDate: moment().valueOf(),
      }) || [];
      this.storeAreaDocksBasicInfo.data = this.buildDocksData(result);
    },
    async loadReceiptOrderRuleDetail() {
      const result = await receiptOrderRuleDetail({}, {
        receiptOrderId: this.$route.query.id,
      });
      this.isAssignDock = result?.configCheckIn?.isAssignDock;
    },
    // 获取子组件中的文件数据
    getFileData(data) {
      this.fileData = [...data];
    },
    getStoreData(data) {
      this.form.handling = data.handling;
      this.form.outsourceHandling = data.outsourceHandling;
    },
  },
};
</script>
<style lang="scss" scoped>
.block {
  .el-form-item {
    margin: 0;
  }
}

.title {
  border-left: 3px solid #02a7f0;
  padding-left: 10px;

  h3 {
    font-size: 14px;
  }
}

.store_info {
  margin-top: 20px;
}

.body {
  padding-left: 40px;
  width: calc(100% - 40px);
}

.sub_title {
  margin-top: 20px;
  padding: 4px 32px 4px 4px;
  display: inline-block;
  transform: skew(-25deg);
  -webkit-transform: skew(-25deg);
  -moz-transform: skew(-25deg);
  -o-transform: skew(-25deg);
  -ms-transform: skew(-25deg);
  background-color: #f2f2f2;

  h4 {
    font-weight: normal;
    transform: skew(25deg);
    -webkit-transform: skew(25deg);
    -moz-transform: skew(25deg);
    -o-transform: skew(25deg);
    -ms-transform: skew(25deg);
  }
}

.file-show-box {
  padding: 16px 24px;
  background-color: #f6f8fa;
  border-radius: 4px;
  width: 86%;

  .file-rows {
    padding: 12px 0;
    height: 72px;
    overflow: hidden;

    .file-info {
      float: left;

      & > div {
        height: 24px;
      }

      .file-name {
        color: #606266;
        font-size: 14px;
      }

      .file-upload-time {
        font-size: 14px;
        color: #909399;
      }
    }

    .file-opera {
      padding-top: 12px;
      float: right;

      .text-color {
        color: #f56c6c;
      }
    }
  }
}
</style>
