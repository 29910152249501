<template>
  <div>
    <el-dialog
      v-model="visible"
      title="收货月台使用情况"
      width="50%"
      :before-close="handleClose"
    >
      <template #title>
        <div class="title-box">
          <span class="title-content">收货月台使用情况</span> <DockTableLegend />
        </div>
      </template>
      <DockTableView
        ref="tableView"
        :table-data="[...basicDocks.data]"
        :table-header="basicDocks.column"
      />
    </el-dialog>
  </div>
</template>
<script>
import DockTableLegend from '../dockTableLegend/index.vue';
import DockTableView from '../dockTableView/index.vue';

export default {
  name: 'ArrivalDockUpdate',
  components: { DockTableLegend, DockTableView },
  mixins: [],
  props: {
    basicDocks: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      updateForm: {
        cars: [],
        newDock: {},
      },
      visible: false,
    };
  },
  created() {},
  methods: {
    show(info) {
      this.updateForm = {
        ...info,
      };
      this.visible = true;
    },
    handleClose() {
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.title-box {
  display: flex;

  .title-content {
    font-size: 18px;
    font-weight: 500;
    color: #171717;
  }
}
</style>
